import React from 'react';

import { graphql } from 'gatsby';

import TextVisuelTwoCol from '../components/ContentType/TextVisuelTwoCol/TextVisuelTwoCol';
import useMedias from '../hooks/useMedias';
import ShapeBackground from '../Molecules/ShapeBackground/ShapeBackground';
import TitleSection from '../Molecules/TitleSection/TitleSection';

const TextVisuel = ({ data }) => {
  const { getImage } = useMedias();

  const imagesArray = data?.allImages?.edges;
  const pageData = data?.pageData?.edges[0].node;

  return (
    <div className="test_left_right_visu_page1">
      <h1>Test Page</h1>
      <section className="section_content">
        <div className="wrapper_page_xs">
          <div className="custom_table_wrapper">
            <table className="custom_table">
              <caption>Chiffre d'Affaires</caption>
              <thead>
                <tr>
                  <th scope="col">En millions d’euros</th>
                  <th scope="col">2018</th>
                  <th scope="col">2019</th>
                  <th scope="col">2020</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <th scope="row">Chiffre d’Affaires</th>
                  <td>13 190</td>
                  <td>13 688</td>
                  <td>12 297</td>
                </tr>
                <tr>
                  <th scope="row">Evolution N/N-1</th>
                  <td>+13%</td>
                  <td>+4%</td>
                  <td>-9%</td>
                </tr>
              </tbody>
            </table>
          </div>
          <TextVisuelTwoCol
            title="Title Default"
            colorTitle="color_dark_bleu"
            visuel={getImage(
              imagesArray,
              pageData?.relationships?.field_image?.drupal_internal__mid
            )}
            extremeLeft={true}
            alignLeft={false}
            orderInverse={false}
            alignTop={true}
            text="<p>Loramea zeiajz epakzje ahzej azehoazhe azhjeo azheja zehoajzhe ajzeh aozehakzgeauzge iazheoazeiazeiazge iagzei azgeiazge iazughe iauzge iuagzeiuagz</p>"
            colorDescription="color_black"
            visuelAspectRatioMobile={1.34241245}
            visuelAspectRatioDesktop={1.04107143}
            //visuelMaxWidthDesktop={352}
          />
        </div>
      </section>

      <section className="section_content">
        <div className="wrapper_page_xs">
          <TextVisuelTwoCol
            title="Title Default"
            colorTitle="color_dark_bleu"
            visuel={getImage(
              imagesArray,
              pageData?.relationships?.field_image?.drupal_internal__mid
            )}
            extremeLeft={true}
            alignLeft={false}
            orderInverse={false}
            alignTop={false}
            text="<p>Loramea zeiajz epakzje ahzej azehoazhe azhjeo azheja zehoajzhe ajzeh aozehakzgeauzge iazheoazeiazeiazge iagzei azgeiazge iazughe iauzge iuagzeiuagz</p>"
            colorDescription="color_black"
            visuelAspectRatioMobile={1.34241245}
            visuelAspectRatioDesktop={1.04107143}
            //visuelMaxWidthDesktop={352}
          />
        </div>
      </section>

      <section className="section_content">
        <div className="wrapper_page_xs">
          <TextVisuelTwoCol
            title="Title Default"
            colorTitle="color_dark_bleu"
            visuel={getImage(
              imagesArray,
              pageData?.relationships?.field_image?.drupal_internal__mid
            )}
            extremeRight={true}
            alignLeft={false}
            orderInverse={true}
            alignTop={false}
            text="<p>Loramea zeiajz epakzje ahzej azehoazhe azhjeo azheja zehoajzhe ajzeh aozehakzgeauzge iazheoazeiazeiazge iagzei azgeiazge iazughe iauzge iuagzeiuagz</p>"
            colorDescription="color_black"
            visuelAspectRatioMobile={1.34241245}
            visuelAspectRatioDesktop={1.04107143}
            //visuelMaxWidthDesktop={352}
          />
        </div>
      </section>

      <section className="section_content">
        <div className="wrapper_page_xs">
          <TextVisuelTwoCol
            title="Title Default"
            colorTitle="color_dark_bleu"
            visuel={getImage(
              imagesArray,
              pageData?.relationships?.field_image?.drupal_internal__mid
            )}
            alignLeft={false}
            orderInverse={true}
            alignTop={false}
            text="<p>Loramea zeiajz epakzje ahzej azehoazhe azhjeo azheja zehoajzhe ajzeh aozehakzgeauzge iazheoazeiazeiazge iagzei azgeiazge iazughe iauzge iuagzeiuagz</p>"
            colorDescription="color_black"
            visuelAspectRatioMobile={1.34241245}
            visuelAspectRatioDesktop={1.04107143}
            //visuelMaxWidthDesktop={352}
          />
          <TextVisuelTwoCol
            title="Title Default"
            colorTitle="color_dark_bleu"
            visuel={getImage(
              imagesArray,
              pageData?.relationships?.field_image?.drupal_internal__mid
            )}
            alignLeft={false}
            orderInverse={false}
            alignTop={false}
            text="<p>Loramea zeiajz epakzje ahzej azehoazhe azhjeo azheja zehoajzhe ajzeh aozehakzgeauzge iazheoazeiazeiazge iagzei azgeiazge iazughe iauzge iuagzeiuagz</p>"
            colorDescription="color_black"
            visuelAspectRatioMobile={1.34241245}
            visuelAspectRatioDesktop={1.04107143}
            //visuelMaxWidthDesktop={352}
          />
          <TextVisuelTwoCol
            title="Title Default"
            colorTitle="color_dark_bleu"
            visuel={getImage(
              imagesArray,
              pageData?.relationships?.field_image?.drupal_internal__mid
            )}
            alignLeft={false}
            orderInverse={true}
            alignTop={false}
            text="<p>Loramea zeiajz epakzje ahzej azehoazhe azhjeo azheja zehoajzhe ajzeh aozehakzgeauzge iazheoazeiazeiazge iagzei azgeiazge iazughe iauzge iuagzeiuagz</p>"
            colorDescription="color_black"
            //visuelMaxWidthDesktop={352}
          />
          <TextVisuelTwoCol
            title="Title Default"
            colorTitle="color_dark_bleu"
            visuel={getImage(
              imagesArray,
              pageData?.relationships?.field_image?.drupal_internal__mid
            )}
            alignLeft={false}
            orderInverse={false}
            alignTop={false}
            text="<p>Loramea zeiajz epakzje ahzej azehoazhe azhjeo azheja zehoajzhe ajzeh aozehakzgeauzge iazheoazeiazeiazge iagzei azgeiazge iazughe iauzge iuagzeiuagz</p>"
            colorDescription="color_black"
            visuelMaxWidthDesktop={352}
          />
          <TextVisuelTwoCol
            title="Title Default"
            colorTitle="color_dark_bleu"
            visuel={getImage(
              imagesArray,
              pageData?.relationships?.field_image?.drupal_internal__mid
            )}
            alignLeft={false}
            orderInverse={false}
            alignTop={false}
            text="<p>Loramea zeiajz epakzje ahzej azehoazhe azhjeo azheja zehoajzhe ajzeh aozehakzgeauzge iazheoazeiazeiazge iagzei azgeiazge iazughe iauzge iuagzeiuagz</p>"
            colorDescription="color_black"
            visuelAspectRatioMobile={1.34241245}
            visuelAspectRatioDesktop={0.699801193}
            visuelMaxWidthDesktop={352}
          />
          <TextVisuelTwoCol
            title="Title Default"
            colorTitle="color_dark_bleu"
            visuel={getImage(
              imagesArray,
              pageData?.relationships?.field_image?.drupal_internal__mid
            )}
            alignLeft={false}
            orderInverse={true}
            alignTop={false}
            text="<p>Loramea zeiajz epakzje ahzej azehoazhe azhjeo azheja zehoajzhe ajzeh aozehakzgeauzge iazheoazeiazeiazge iagzei azgeiazge iazughe iauzge iuagzeiuagz</p>"
            colorDescription="color_black"
            visuelAspectRatioMobile={1.34241245}
            visuelAspectRatioDesktop={0.699801193}
            visuelMaxWidthDesktop={352}
          />

          <TextVisuelTwoCol
            title="Title Default"
            colorTitle="color_dark_bleu"
            visuel={getImage(
              imagesArray,
              pageData?.relationships?.field_image?.drupal_internal__mid
            )}
            alignLeft={false}
            orderInverse={false}
            alignTop={false}
            text="<p>Loramea zeiajz epakzje ahzej azehoazhe azhjeo azheja zehoajzhe ajzeh aozehakzgeauzge iazheoazeiazeiazge iagzei azgeiazge iazughe iauzge iuagzeiuagz</p>"
            colorDescription="color_black"
          />
          <TextVisuelTwoCol
            title="Text Full Width"
            colorTitle="color_dark_bleu"
            visuel={getImage(
              imagesArray,
              pageData?.relationships?.field_image?.drupal_internal__mid
            )}
            alignLeft={false}
            orderInverse={true}
            alignTop={false}
            text="<p>Loramea zeiajz epakzje ahzej azehoazhe azhjeo azheja zehoajzhe ajzeh aozehakzgeauzge iazheoazeiazeiazge iagzei azgeiazge iazughe iauzge iuagzeiuagz</p>"
            colorDescription="color_black"
            visuelAspectRatioMobile={1.34241245}
            visuelAspectRatioDesktop={0.699801193}
            visuelMaxWidthDesktop={352}
          />

          <TextVisuelTwoCol
            title="#Text Full Width Title Hash"
            titleHash={true}
            colorTitle="color_dark_bleu"
            visuel={getImage(
              imagesArray,
              pageData?.relationships?.field_image?.drupal_internal__mid
            )}
            alignLeft={false}
            orderInverse={true}
            alignTop={false}
            text="<p>Loramea zeiajz epakzje ahzej azehoazhe azhjeo azheja zehoajzhe ajzeh aozehakzgeauzge iazheoazeiazeiazge iagzei azgeiazge iazughe iauzge iuagzeiuagz</p>"
            colorDescription="color_black"
            visuelAspectRatioMobile={1.34241245}
            visuelAspectRatioDesktop={0.699801193}
            visuelMaxWidthDesktop={352}
          />

          <TextVisuelTwoCol
            title="Text Full Width"
            titleBackground={true}
            colorTitle="color_white"
            visuel={getImage(
              imagesArray,
              pageData?.relationships?.field_image?.drupal_internal__mid
            )}
            alignLeft={false}
            orderInverse={true}
            alignTop={false}
            text="<p>Loramea zeiajz epakzje ahzej azehoazhe azhjeo azheja zehoajzhe ajzeh aozehakzgeauzge iazheoazeiazeiazge iagzei azgeiazge iazughe iauzge iuagzeiuagz</p>"
            colorDescription="color_black"
            visuelAspectRatioMobile={1.34241245}
            visuelAspectRatioDesktop={0.699801193}
            visuelMaxWidthDesktop={352}
          />

          <TextVisuelTwoCol
            title="Text Full Width"
            colorTitle="color_dark_bleu"
            visuel={getImage(
              imagesArray,
              pageData?.relationships?.field_image?.drupal_internal__mid
            )}
            alignLeft={false}
            orderInverse={true}
            alignTop={false}
            text="<p>Loramea zeiajz epakzje ahzej azehoazhe azhjeo azheja zehoajzhe ajzeh aozehakzgeauzge iazheoazeiazeiazge iagzei azgeiazge iazughe iauzge iuagzeiuagz</p>"
            colorDescription="color_black"
          />
          <TextVisuelTwoCol
            title="Title Default"
            colorTitle="color_dark_bleu"
            visuel={getImage(
              imagesArray,
              pageData?.relationships?.field_image?.drupal_internal__mid
            )}
            alignLeft={false}
            orderInverse={true}
            alignTop={false}
            text="<p>Loramea zeiajz epakzje ahzej azehoazhe azhjeo azheja zehoajzhe ajzeh aozehakzgeauzge iazheoazeiazeiazge iagzei azgeiazge iazughe iauzge iuagzeiuagz</p>"
            colorDescription="color_black"
          />
        </div>
      </section>

      <section className="section_content">
        <ShapeBackground top="left" color="catskill_white" bottom="left">
          <div className="wrapper_page_xs">
            <TitleSection
              title="Title section of the page"
              description="azekjbazkeg aizehua oizehuiazh eihauzeiaze"
            />
            <TextVisuelTwoCol
              title="Title Default"
              colorTitle="color_dark_bleu"
              visuel={getImage(
                imagesArray,
                pageData?.relationships?.field_image?.drupal_internal__mid
              )}
              alignLeft={false}
              orderInverse={true}
              alignTop={false}
              text="<p>Loramea zeiajz epakzje ahzej azehoazhe azhjeo azheja zehoajzhe ajzeh aozehakzgeauzge iazheoazeiazeiazge iagzei azgeiazge iazughe iauzge iuagzeiuagz</p>"
              colorDescription="color_black"
            />
            <TextVisuelTwoCol
              title="Title Default"
              colorTitle="color_dark_bleu"
              extremeLeft={true}
              visuel={getImage(
                imagesArray,
                pageData?.relationships?.field_image?.drupal_internal__mid
              )}
              alignLeft={false}
              orderInverse={false}
              alignTop={false}
              text="<p>Loramea zeiajz epakzje ahzej azehoazhe azhjeo azheja zehoajzhe ajzeh aozehakzgeauzge iazheoazeiazeiazge iagzei azgeiazge iazughe iauzge iuagzeiuagz</p>"
              colorDescription="color_black"
            />
            <TextVisuelTwoCol
              title="Title Default"
              colorTitle="color_dark_bleu"
              extremeRight={true}
              visuel={getImage(
                imagesArray,
                pageData?.relationships?.field_image?.drupal_internal__mid
              )}
              alignLeft={false}
              orderInverse={true}
              alignTop={false}
              text="<p>Loramea zeiajz epakzje ahzej azehoazhe azhjeo azheja zehoajzhe ajzeh aozehakzgeauzge iazheoazeiazeiazge iagzei azgeiazge iazughe iauzge iuagzeiuagz</p>"
              colorDescription="color_black"
            />
            <TextVisuelTwoCol
              title="Title Default"
              colorTitle="color_dark_bleu"
              extremeLeft={true}
              visuel={getImage(
                imagesArray,
                pageData?.relationships?.field_image?.drupal_internal__mid
              )}
              alignLeft={false}
              orderInverse={false}
              alignTop={false}
              text="<p>Loramea zeiajz epakzje ahzej azehoazhe azhjeo azheja zehoajzhe ajzeh aozehakzgeauzge iazheoazeiazeiazge iagzei azgeiazge iazughe iauzge iuagzeiuagz</p>"
              colorDescription="color_black"
            />
            <TextVisuelTwoCol
              title="Title Default"
              colorTitle="color_dark_bleu"
              extremeRight={true}
              visuel={getImage(
                imagesArray,
                pageData?.relationships?.field_image?.drupal_internal__mid
              )}
              alignLeft={false}
              orderInverse={true}
              alignTop={false}
              text="<p>Loramea zeiajz epakzje ahzej azehoazhe azhjeo azheja zehoajzhe ajzeh aozehakzgeauzge iazheoazeiazeiazge iagzei azgeiazge iazughe iauzge iuagzeiuagz</p>"
              colorDescription="color_black"
            />
            <TextVisuelTwoCol
              title="Title Default"
              colorTitle="color_dark_bleu"
              extremeLeft={true}
              visuel={getImage(
                imagesArray,
                pageData?.relationships?.field_image?.drupal_internal__mid
              )}
              alignLeft={false}
              orderInverse={false}
              alignTop={false}
              text="<p>Loramea zeiajz epakzje ahzej azehoazhe azhjeo azheja zehoajzhe ajzeh aozehakzgeauzge iazheoazeiazeiazge iagzei azgeiazge iazughe iauzge iuagzeiuagz</p>"
              colorDescription="color_black"
              cta={{
                ctaText: 'Label de CTA',
                ctaLink: '/',
              }}
            />
          </div>
        </ShapeBackground>
      </section>
    </div>
  );
};

export const query = graphql`
  query TextVisuelData($imagesID: [Int!]) {
    allImages: allMediaImage(
      filter: {
        langcode: { eq: "fr" }
        drupal_internal__mid: { in: $imagesID }
      }
    ) {
      edges {
        node {
          drupal_internal__mid
          relationships {
            field_media_image {
              uri {
                url
              }
              image_style_uri {
                gatsby_thumbnail
                gatsby_medium
                gatsby_large
                gatsby_wide
                gatsby_medium_carousel
                gatsby_thumbnail_carousel
                gatsby_innov_carousel
                gatsby_activites_carousel        
              }
            }
          }
        }
      }
    }
  }
`;

export default TextVisuel;
